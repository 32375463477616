<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('project.edit','编辑保养计划'):$l('project.add','新增保养计划')"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('project.labelWidth','80px')">
      <el-tag class="tag">电梯信息</el-tag> <el-button size="small" type="text" @click="$refs.elevatorSelect.open()">选择电梯</el-button>
      <div class="vm-separate content">
        <div>楼盘名称:{{elevator.location.realEstateName}}</div>
        <div>详细地址:{{elevator.location.detailAddress}}</div>
        <div></div>
      </div>
      <div class="vm-separate content">
        <div>楼宇名称:{{elevator.location.buildingName}}</div>
        <div>有无机房:{{elevator.location.machineRoom?'有':"无"}}</div>
        <div></div>
      </div>
      <div class="vm-separate content">
        <div>注册代码:{{elevator.brief.regCode}}</div>
        <div>出厂编号:{{elevator.brief.factoryCode}}</div>
        <div>出厂日期:{{elevator.brief.factoryDate}}</div>
      </div>
      <div class="vm-separate content">
        <div>电梯品牌:{{elevator.brief.brandName}}</div>
        <div>电梯型号:{{elevator.brief.modelName}}</div>
        <div>控制系统:{{elevator.brief.controlSystemDesc}}</div>
      </div>
      <div class="vm-separate" style="margin-top: 10px">
        <el-form-item :label="$l('project.name','保养类型')" prop="name">
          <vm-dict-select v-model="model.type" type="maintainType" style="width: 193px"></vm-dict-select>
        </el-form-item>
        <div class="vm-separate">
          <el-form-item :label="$l('project.assigneeName','保养班组')" prop="assigneeId">
            <vm-autocomplete
              :model.sync="model"
              :value-name="model.teamName"
              value-key="name"
              linkage-name="id"
              require-name="teamId"
              :value="{teamName:'name',teamId:'id'}"
              url="organization/team/list"></vm-autocomplete>
          </el-form-item>
        </div>
      </div>
      <div class="vm-separate">
        <el-form-item label="保养计划日期" prop="planStartTime">
          <el-date-picker
            v-model="model.planDate"
            type="datetime"
            style="width: 193px"
            value-format="yyyy-MM-dd hh:mm:ss"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实际保养日期" prop="planEndTime">
          <el-date-picker
            v-model="model.actualDate"
            type="datetime"
            style="width: 193px"
            value-format="yyyy-MM-dd hh:mm:ss"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </div>
    </el-form>

    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>

      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
      <!--      <el-button v-if="model.id" type="primary" @click="toTask">转任务</el-button>-->
    </span>
    <user-select ref="userSelectWb" @select="selectUserWb"></user-select>
    <customer-select ref="customerSelect" @select="selectCustomer($event)"></customer-select>
    <elevator-select ref="elevatorSelect" @select="elevatorSelect"></elevator-select>
    <project-select ref="projectSelect" @select="projectSelect"></project-select>
  </el-dialog>
</template>
<script>
  import UserSelect from "@/views/user/UserSelect";
  import CustomerSelect from "@/views/customer/CustomerSelect";
  import ProjectSelect from "@/views/project/ProjectSelect";
  import ElevatorSelect from "@/common/select/ElevatorSelect";
  import VmAutocomplete from "@/components/VmAutocomplete";

  export default {
    components: {VmAutocomplete, UserSelect, CustomerSelect,ProjectSelect,ElevatorSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        elevator:{location:{},brief:{},maintain:{}},
        model: {
          id: 0,
          name: "",
          customerId: "",
          customerName: "",
          wbManagerId: "",
          wbManagerName: "",
          status: "",
          tasks:[],
          tasksMapping:[],
        },
      };
    },
    methods: {
      elevatorSelect(row) {
        this.$http.get(`elevator/summary/${row.id}`).then(res=>{
          this.elevator=res;
        });
        this.$set(this.model,"elevatorId",row.id);
      },
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;

        if (id) {
          this.getData();

        }

      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/maintain-plan/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.elevatorSelect({id:data.elevatorId});
            this.model = data;
          });
        }
      },
      selectCustomer(row) {

        this.model.customerId = row.id;
        this.model.customerName = row.name;
      },
      projectSelect(row,index) {
        this.$set(this.model.tasks[index],"projectName",row.name);
        this.$set(this.model.tasks[index],"projectId",row.id);

      },

      selectUserWb(row) {
        this.$set(this.model,"assigneeId",row.id);
        this.$set(this.model,"assigneeName",row.name);
      },
      toTask() {
        const arr = [];
        this.model.tasks.forEach(item=>{
          if (item.checked) {
            arr.push(item.id);
          }
        });
        this.$http.post("maintenance/maintain-order/from-task",arr).then(()=>{
          this.$message.success("已转任务");
        });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const parmas = {...this.model};
            this.$http
              .save("maintenance/maintain-plan", parmas)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      addLine() {
        this.model.tasks.push({
          key: this.$util.genKey(),
          "id": 0,
          "tenantId": 0,
          "planId": 0,
          "projectId": 0,
          "elevatorId": 0,
          "cycle": "",
          "planDate": "",
          "planWorkHour": 0,
          "assigned": true,
          "assigneeId": 0,
          "assignedTime": "",
          "maintainOrderId": 0,
          "actualStartTime": "",
          "actualEndTime": "",
          "actualWorkHour": 0,
          "projectName": "",
          "elevatorCode": "",
          "assigneeName": "",
        });
      },
      deleteLine() {
        this.model.tasks.pop();
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
