<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('company.edit','批量修改类型班组'):$l('company.add','批量修改类型班组')"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('company.labelWidth','130px')">
      <div class="vm-separate" style="margin-top: 10px">
        <el-form-item :label="$l('project.name','保养类型')" prop="name">
          <vm-dict-select v-model="model.type" type="maintainType" style="width: 193px"></vm-dict-select>
        </el-form-item>
        <el-form-item :label="$l('project.assigneeName','保养班组')" prop="assigneeId">
          <vm-autocomplete
            :model.sync="model"
            :value-name="model.teamName"
            value-key="name"
            linkage-name="id"
            require-name="teamId"
            :value="{teamName:'name',teamId:'id'}"
            url="organization/team/list"></vm-autocomplete>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import VmAutocomplete from "@/components/VmAutocomplete";
  export default {
    components: {VmAutocomplete},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          elevatorIds: [],
          plans: [],
        },
      };
    },
    methods: {
      open(selectList) {
        const arr = [];
        selectList.forEach(item=>{
          arr.push(item.id);
        });
        this.model.elevatorIds = arr;
        this.plans = selectList;
        this.dialogVisible = true;
      },

      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .post("/maintenance/maintain-plans", {plans:this.plans,type:this.model.type,teamId:this.model.teamId})
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>