<template>
  <el-autocomplete
    v-model="name"
    :style="{width: width}"
    :value-key="valueKey"
    :fetch-suggestions="querySearchAsync"
    placeholder="请输入内容"
    @select="handleSelect">
  </el-autocomplete>
</template>
<script>
//const fileServer = "http://dfs.cricbigdata.com/view/";
  export default {
    props: {
      url: String,
      value: [String, Array],
      model:Object,
      filter:Object,
      linkage:Object,
      valueKey:{
        type:String,
        default:"name",
      },
      valueName:String,
      linkageName:{
        type:String,
        default: "",
      },
      requireName:{
        type:String,
        default: "",
      },
      width:{
        type:String,
        default:"193px",
      },
    },
    data() {
      return {
        name:"",
      };
    },
    watch:{
      valueName:{
        handler(parmars) {
          this.name=parmars;
        },
        deep:true,
      },
      linkage:{
        handler(parmars) {
          this.filter=parmars.filter;
          if (this.requireName) {
            if (typeof this.filter[this.requireName] === "number") {
              this.loadAll();
            }

          }

        },
        deep:true,
      },
    },
    mounted() {
      this.loadAll();
    },
    methods: {
      loadAll() {

        let http =  this.$http.get(this.url, this.filter);
        http.then(data => {
          this.data=data;
        });
      },
      querySearchAsync(queryString, cb) {
        this.$emit("update:filter", queryString);
        var restaurants = this.data;
        var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1000 * Math.random());
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.name.toLowerCase().indexOf(queryString.toLowerCase())>-1);
        };
      },
      handleSelect(item) {
        if (this.linkageName) {
          console.log(this.linkageName,item[this.linkageName],81);
          this.$emit("update:filter", item[this.linkageName]);

        }else {
          this.$emit("update:filter", item[this.valueKey]);

        }
        let obj =this.model;
        if (this.value) {
          if (this.value instanceof Object) {
            for (let [key,value] of Object.entries(this.value)) {
              obj={...obj,[key]:item[value]};
              // filter={...filter,[key]:item[value]};
            }
          }else {
            obj[this.value]=item[this.value];
            // filter[this.value]=item[this.value];
          }
          this.$emit("update:model", obj);
        }
        

      },
    },
  };
</script>
<style lang="scss" scoped>
.file {
  margin-left: 10px;
  font-size: 14px;
}
.upload{
  display: inline-block;
  margin-left:  5px;
}
</style>
