<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择员工"
    class="small-padding"
    width="1000px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="organization/employee/page">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="员工名称">
            <el-input v-model="filter.name" type="iotDeviceModel"></el-input>
          </vm-search>
          <vm-search label="电话号码">
            <el-input v-model="filter.phone" type="iotDeviceModel"></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="员工姓名" align="center"></el-table-column>
      <el-table-column prop="gender" label="性别" align="center">
      <template slot-scope="scope">
        <div v-if="scope.row.gender === 'male'">男</div>
        <div v-if="scope.row.gender === 'female'">女</div>
      </template>
      </el-table-column>
      <el-table-column prop="phone" label="电话号码" align="center"></el-table-column>
      <el-table-column prop="remark" label="备注信息" align="center" width="90">
      </el-table-column>
      <el-table-column width="70" :label="$l('common.function','操作')" align="center">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          phone: "",
          name: "",
          type: "",
          status: "enabled",
          index:0,
          idx:0,
        },
      };
    },
    methods: {
      open(index,idx=0) {
        this.dialogVisible = true;

        this.index=index;
        this.idx=idx;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row,this.index,this.idx);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
